import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Nav from '../common/Nav'
import VirtualizedAutocomplete from '../common/VirtualizedAutocomplete';
import logo from '../../dist/images/GOFOLIO_NAVY.svg'
import { ListFiltered } from '../security/List'
import { getUserSecurities, updateSecurity, removeSecurity } from '../../api/api';
import { useNavigate } from "react-router-dom";


const MySecurities = (props) => {
  const navigate = useNavigate();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [userSecurities, setUserSecurities] = useState({});
  const [deleteSecurityErrorMsg, setDeleteSecurityErrorMsg] = useState('');
  const [addSecurityErrorMsg, setAddSecurityErrorMsg] = useState('');
  const [newSecurityData, setNewSecurityData] = useState({});
  const [allowAddSecurity, setAllowAddSecurity] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});

  useEffect(() => {
    const fullHeight = () => {
      const elements = document.querySelectorAll('.js-fullheight');
      elements.forEach((element) => {
        element.style.height = window.innerHeight + 'px';
      });
    };

    const handleResize = () => {
      fullHeight();
    };

    window.addEventListener('resize', handleResize);

    const sidebarToggle = () => {
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.toggle('active');
    };

    const sidebarCollapse = document.getElementById('sidebarCollapse');
    sidebarCollapse.addEventListener('click', sidebarToggle);

    // Clean up event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
      sidebarCollapse.removeEventListener('click', sidebarToggle);
    };
  }, []);

  const fetchUserSecurities = async () => {
    var res = {}
    try {
      res = await getUserSecurities();
    } catch (err) {
      navigate("/login");
    }
    if (res.status === 200) {
      setUserSecurities(res.data);
    } else {
      navigate("/login");
    }
  }
  useEffect(() => { fetchUserSecurities(); }, []);

  const clearAddNewSecurity = () => {
    setAllowAddSecurity(false);
    setNewSecurityData({});
  }

  const handleSetNewSecurityData = (e, data) => {
    console.log(`handleSetNewSecurityData data: ${JSON.stringify(data)}`);
    if (!data) {
      clearAddNewSecurity();
    } else {
      setNewSecurityData(data);
      if (data.id === "") {
        setAllowAddSecurity(false);
      } else {
        setAllowAddSecurity(true);
      }
    }
  };

  const addNewSecurity = () => {
    setAddSecurityErrorMsg('');
    setAllowAddSecurity(false);
    return updateSecurity(newSecurityData['id']).then((res) => {
      if (res.status === 200) {
        setUserSecurities(prevState => ({
          ...prevState,
          [newSecurityData['id']]: newSecurityData['symbol'],
        }));
        clearAddNewSecurity();;
      } else {
        setAddSecurityErrorMsg(res.data.msg);
      }
    })
  };
  const deleteSecurity = (data, confirmed = false) => {
    if (!confirmed || data['id'] !== confirmDeleteData['id']) {
      setConfirmDeleteData(data);
      setShowConfirmDeleteModal(true);
    } else {
      return removeSecurity(data['id']).then((res) => {
        if (res.status === 200) {
          let copyOfObject = { ...userSecurities }
          delete copyOfObject[data['id']]

          setUserSecurities(data => ({
            ...copyOfObject
          }));
          setShowConfirmDeleteModal(false);
        } else {
          setDeleteSecurityErrorMsg(res.data.msg);
        }
      })
    }
  }

  return (
    <>
      <Nav heading="My Securities" setPage={props.setPage} page={props.page} name={props.name} />
      <div className="row py-2">
        <div className="col-sm-6 col-md-3">
          <>
            <VirtualizedAutocomplete
              id="add-security"
              options={props.securities}
              onChange={handleSetNewSecurityData}
            />
          </>
        </div>
        <div className="col-sm-3 col-md-3">
          <button className={`btn button-dark ${allowAddSecurity ? "" : "disabled"}`} onClick={addNewSecurity}>ADD</button>
        </div>
      </div>
      <div className="row py-2"><div className="col"><p className="error-msg">{addSecurityErrorMsg}</p></div></div>
      <ListFiltered data={userSecurities} removeSecurityHook={deleteSecurity} />

      <Modal show={showConfirmDeleteModal}>

        <div className="text-center py-3">
          <img src={logo} width="228.996px" height="93.9961" alt='' />
        </div>

        <div className="card add-portfolio-card mx-auto w-75 w-xs-100 mb-2">
          <div className="card-body mb-2">
            <div className="container text-center px-1 pt-1">

              <div className="text-center">
                <p>Are you sure you want to delete this security?</p>
              </div>
              <div className="text-center">
                <p>{confirmDeleteData['name']}</p>
              </div>

              {deleteSecurityErrorMsg ? <div className="text-center">
                <p className="error-msg">{deleteSecurityErrorMsg}</p>
              </div> : null}

              <div className="row text-center">
                <div className="col mt-3">
                  <button className="btn button-dark" data-bs-toggle="modal" data-bs-target="#confirmSecurityDelete">CANCEL</button>
                </div>
                <div className="col mt-3">
                  <button type="button" className="btn button-ol-dark" onClick={() => deleteSecurity(confirmDeleteData, true)}>YES, DELETE</button>
                </div>
              </div>

            </div>
          </div>

        </div>
      </Modal>
    </>
  )
}

export default MySecurities