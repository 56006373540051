import React from 'react';
import dropArrow from '../../dist/icons/dropdown_arrowx2.svg';
import overview from '../../dist/icons/GoFolio_OVERVIEW.svg';
import profile from '../../dist/icons/GoFolio_MY_ACCOUNT.svg';
import security from '../../dist/icons/GoFolio_MY_SECURITIES.svg';
import portfolio from '../../dist/icons/GoFolio_PORTFOLIO.svg';
import help from '../../dist/icons/GoFolio_HELP.svg';
import logout from '../../dist/icons/GoFolio_LOGOUT.svg';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";

// logged in user only
// used in Dashboard - lefthand Nav menu on mobile

const Nav = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="d-block d-md-flex flex-wrap align-items-center pt-4 pb-3">
        <div className="mb-1 mb-md-0 text-center text-md-start">
          <Link className="nav-link ms-0" style={{ borderBottom: 'none' }}>
            <span className="heading">
              {props.heading}
            </span>
          </Link>
        </div>
        <div className="ms-auto mb-1">
          <div className="nav-link text-end" tabIndex="-1" aria-disabled="true" style={{ borderBottom: 'none' }}>
            <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
              <div className='d-md-none'>
                <span data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <i className="fa fa-bars me-1" />

                </span>
              </div>
              <div className='d-flex item'>
                <span className="ms-2">{props.name}</span>
              </div>
              <div className='d-flex item'>
                <span className="nav-link B me-0">
                  <p>{props.name[0].toUpperCase()}</p>
                </span>
                <div className="btn-group">
                  <Link className="dropdown-toggle nav-link me-0" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ borderBottom: 'none' }}>
                    <img src={dropArrow} alt='' />
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-lg-end mt-2 me-1">
                    <li><Link className="dropdown-item" to="/app" onClick={() => props.setPage('account')} >Profile</Link></li>
                    <li><Link className="dropdown-item" to="/app" onClick={() => props.setPage('account')} >Account Setting</Link></li>
                    <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      {/* Below used in mobile nav */}
      <div
        className="offcanvas  offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header" >
          <h5 className="offcanvas-title text-white" id="offcanvasExampleLabel">
            Dashboard
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body p-0">
          <ul className="nav flex-column">
            <li className={location.pathname === "/dashboard" ? "active-link" : ""}>
              <Link to="/app" onClick={() => props.setPage('dashboard')} className={props.page === "/dashboard" ? "active-link" : ""}>
                <span><img className='fa' src={overview} width={28} alt='' /></span> <span className='colp'>Dashboard</span>
              </Link>
            </li>
            <li className={location.pathname === "/account" ? "active-link" : ""}>
              <Link to="/app" onClick={() => props.setPage('account')} className={props.page === "/account" ? "active-link" : ""}>
                <span><img className='fa' src={profile} width={28} alt='' /></span>   <span className='colp'>My Account</span>
              </Link>
            </li>
            <li className={location.pathname === "/" ? "active-link" : ""}>
              <Link to="/app" onClick={() => props.setPage('portfolios')} className={props.page === "portfolios" ? "active-link" : ""}>
                <span><img className='fa' src={portfolio} width={28} alt='' /></span>   <span className='colp'>My Portfolios</span>
              </Link>
            </li>
            <li className={location.pathname === "/securities" ? "active-link" : ""}>
              <Link to="/app" onClick={() => props.setPage('securities')} className={props.page === "securities" ? "active-link" : ""}>
                <span><img className='fa' src={security} width={28} alt='' /></span>   <span className='colp'>My Securities</span>
              </Link>
            </li>
            <li className={location.pathname === "/help" ? "active-link" : ""}>
              <Link to="/app" onClick={() => props.setPage('faq')} className={props.page === "faq" ? "active-link" : ""}>
                <span><img className='fa' src={help} width={28} alt='' /></span>   <span className='colp'>Help</span>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate('/logout')}>
                <span><img className='fa' src={logout} width={28} alt='' /></span>   <span className='colp'>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>

  )
}

export default Nav