export const initialState = {
    isLoading: false,
    isUpdating: false,
    error: '',
    showConfirmDeleteModal: false,
    newPortfolioAlwaysAcceptDisclaimer: false,
    acceptEmails: false,
    origUserData: {},
}

export function reducer(state, action) {
    console.log(`reducer action.type: ${action.type}`)

    switch (action.type) {
        case 'isLoading': {
            return {
                ...state,
                isLoading: action.data
            };
        }
        case 'isUpdating': {
            return {
                ...state,
                isUpdating: action.data
            };
        }
        case 'error': {
            return {
                ...state,
                error: action.data,
                isLoading: false,
                isUpdating: false,
            };
        }
        case 'showConfirmDeleteModal': {
            return {
                ...state,
                showConfirmDeleteModal: action.data
            };
        }
        case 'newPortfolioAlwaysAcceptDisclaimer': {
            return {
                ...state,
                newPortfolioAlwaysAcceptDisclaimer: action.data
            };
        }
        case 'acceptEmails': {
            return {
                ...state,
                acceptEmails: action.data
            };
        }
        case 'setUserData': {
            return {
                ...state,
                origUserData: action.data,
                acceptEmails: action.data.acceptEmails,
                newPortfolioAlwaysAcceptDisclaimer: action.data.newPortfolioAlwaysAcceptDisclaimer,
                isUpdating: false,
            };
        }
        default: {
            console.log(`EmailSettings.state invalid action: ${action.type}`)
        }
    }
}