import React, { useEffect, useState } from 'react';
import { useIdleTimer } from "react-idle-timer"
import Nav from '../common/Nav'
import { TopPerformerList } from '../portfolio/TopPerformerList'
import Spotlight from "../portfolio/Spotlight";
import Sidebar from '../common/Sidebar';
import Navbar from '../common/Navbar';
import logo from '../../dist/images/GOFOLIO_NAVY.svg';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { getPortfolios, getTopPortfolios, refreshPortfolioRun, getPortfolioRun, keepAlive, getAllSecurities, userLogOut, getLatestPortfolio, getDashboard } from '../../api/api';
import { _examplePortfolioData } from '../common/data';
import { _examplePortfolio } from '../common/data';
import { wait, getPortfolioData } from '../common/methods';
import MyPortfolios from './MyPortfolios';
import MySecurities from './MySecurities';
import MyAccount from './MyAccount';
import { FAQAccordion } from '../common/FAQAccordion';
import NewPortfolioForm from './NewPortfolioForm';


const Dashboard = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [page, setPage] = useState('dashboard');
  const [securities, setSecurities] = useState([]);
  const [isLoadingSecurities, setIsLoadingSecurities] = useState(true);
  const [getSecuritiesErr, setGetSecuritiesErr] = useState('');
  const [getPortfoliosErrMsg, setGetPortfoliosErrMsg] = useState('');
  const [spotlightTitle, setSpotlightTitle] = useState('Last Calculated');
  const [spotlightPortfolio, setSpotlightPortfolio] = useState(_examplePortfolioData);
  const [topPortfolios, setTopPortfolios] = useState([]);
  const [topPortfoliosLoading, setTopPortfoliosLoading] = useState(true);
  const [topPortfoliosSelected, setTopPortfoliosSelected] = useState([]);
  const [portfolioDataMap, _setPortfolioDataMap] = useState({ '-1': _examplePortfolioData});
// const _portfolioDataMap = useRef({})

const [portfolios, _setPortfolios] = useState([_examplePortfolio]);

// START: handle idle session
const [remainingIdleTimeInterval, setRemainingIdleTimeInterval] = useState();
const [remainingSessionTimeInit, setRemainingSessionTimeInit] = useState(false);
const [showExtendSessionModal, setShowExtendSessionModal] = useState(false);
const [remainingSessionTime, setRemainingSessionTime] = useState(0);
const [remainingIdleTime, setRemainingIdleTime] = useState(0);
const [canRefresh, setCanRefresh] = useState(false);
const [refreshTokenErrMsg, setRefreshTokenErrMsg] = useState('');

const [isIdle, setIdle] = useState(false);
const onPrompt = () => { }
const handleIdle = () => {
  if (showExtendSessionModal) {
    return;
  }
  if (remainingSessionTime < 60) {
    setRemainingIdleTime(remainingSessionTime);
  } else {
    setRemainingIdleTime(60);
  }
  clearInterval(remainingIdleTimeInterval);
  let interval = setInterval(() => {
    setRemainingIdleTime(
      (prevRemainingTime) =>
        prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
    );
  }, 1000);
  setRemainingIdleTimeInterval(interval);
  setIdle(true);
  setShowExtendSessionModal(true);
};
const idleTimeout = 300000; // 5 minutes
const idleTimer = useIdleTimer({
  timeout: idleTimeout,
  promptBeforeIdle: idleTimeout / 2,
  onPrompt: onPrompt,
  onIdle: handleIdle,
  debounce: 500
});

const secondsToMinutesAndSeconds = (seconds) => {
  let minutes = ~~(seconds / 60);
  let s = Math.round(seconds % 60);
  if (minutes <= 0) {
    return `${s} seconds`;
  }
  return `${minutes} minute${minutes > 1 ? 's' : ''} and ${s} seconds`;
};
const handleLogOut = async () => {
  clearInterval(remainingIdleTimeInterval);
  navigate("/logout");
};
const setKeepAliveRes = async (res) => {
  setCanRefresh(res.data.canRefresh);
  let epochExp = res.data.epochExpirationSeconds;
  let now = new Date().getTime() / 1000; // getTime is in miliseconds
  let remaining = epochExp - now;
  // console.log(`useEffect: epochExp: ${epochExp} now: ${now} remaining: ${remaining}`);
  if (remaining <= 1) {
    await userLogOut();
    navigate("/login");
    return;
  }
  setRemainingSessionTime(remaining);
};
const handleStayLoggedIn = async (refresh = false) => {
  setIdle(false);
  idleTimer.activate();
  clearInterval(remainingIdleTimeInterval);
  if (!refresh) {
    setShowExtendSessionModal(false);
  } else {
    const res = await keepAlive(true);
    if (res.status !== 200) {
      setRefreshTokenErrMsg(res.data);
    } else {
      setShowExtendSessionModal(false);
      setKeepAliveRes(res);
    }
  }
};
const handleTimeout = async () => {
  setShowExtendSessionModal(false);
  await userLogOut();
  navigate("/login");
};
// on page load get remaining session time
useEffect(() => {
  async function checkSession() {
    const res = await keepAlive(false);
    if (res.status !== 200) {
      navigate("/login");
      return;
    }
    setKeepAliveRes(res);
    setRemainingSessionTimeInit(true);
    let interval = setInterval(() => {
      setRemainingSessionTime(
        (prevRemainingTime) => prevRemainingTime > 0 ? prevRemainingTime - 1 : 0
      );
    }, 1000);
    return () => clearInterval(interval);
  }
  checkSession();
}, []);

// tracks remainingSessionTime and logs user out if timeout 
useEffect(() => {
  if (!remainingSessionTimeInit) {
    return;
  }
  // console.log(`useEffect remainingSessionTime: ${secondsToMinutesAndSeconds(remainingSessionTime)}`);
  // if 2 min or less 
  if (remainingSessionTime < 120 && !showExtendSessionModal) {
    // console.log(`useEffect remainingSessionTime < 120 && !showExtendSessionModal setShowExtendSessionModal(true)!!`);
    setShowExtendSessionModal(true);
  }
  if (remainingSessionTime <= 0) {
    // console.log("useEffect remainingSessionTime timeout!");
    handleTimeout();
  }
}, [remainingSessionTime]);

// tracks remainingIdleTime and logs user out if timeout 
useEffect(() => {
  // console.log(`useEffect remainingIdleTime: ${remainingIdleTime} isIdle: ${isIdle}`);
  if (isIdle && remainingIdleTime <= 0) {
    handleTimeout();
  }
}, [isIdle, remainingIdleTime]);
// END: handle idle session

const handleSetPage = (page) => {
  if (page === 'dashboard') {
    // if we created a new portfolio, make sure its data gets updated
    if (spotlightPortfolio.hasOwnProperty('id') && spotlightPortfolio.id > -1) {
      if (portfolioDataMap.hasOwnProperty(spotlightPortfolio.id)) {
        setSpotlightPortfolio(portfolioDataMap[spotlightPortfolio.id]);
      }
      if (spotlightPortfolio.status === "Processing") {
        pollForPortfolio(spotlightPortfolio.id);
      }
    }
    fetchTopPortfolios();
  }
  setPage(page);
}
const fetchDashboard = async () => {
  setTopPortfoliosLoading(true);
  var res = await getDashboard();
  if (res.status === 200) {
    if (res.data.top.length === 0) {
      setTopPortfolios([_examplePortfolio]);
    } else {
      setTopPortfolios(res.data.top);
    }
    if (res.data.latest !== null) {
      setSpotlightPortfolio({ ...res.data.latest });
      if (res.data.latest.status === "Processing") {
        pollForPortfolio(res.data.latest.id);
      }
    }
    setName(res.data.name);
  } else if (res.status === 404 || res.status === 403) {
    navigate("/login");
  } 
  setTopPortfoliosLoading(false);
};
const fetchTopPortfolios = async () => {
  setTopPortfoliosLoading(true);
  var res = await getTopPortfolios();
  if (res.status === 200) {
    if (res.data.length === 0) {
      setTopPortfolios([_examplePortfolio]);
    } else {
      setTopPortfolios(res.data);
    }
    setTopPortfoliosLoading(false);
  } else {
    navigate("/login");
  }
};
const fetchAllSecurities = async () => {
  setIsLoadingSecurities(true);
  var res = await getAllSecurities();
  if (res.status === 200) {
    setSecurities(res.data);
  } else {
    setGetSecuritiesErr(res.data);
  }
  setIsLoadingSecurities(false);
};
const fetchPortfolios = async () => {
  var res = await getPortfolios();
  if (res.status === 200) {
    if (res.data && res.data.length > 0) {
      _setPortfolios(res.data);
    }
  } else {
    setGetPortfoliosErrMsg(res.data);
  }
};
const fetchLatestPortfolio = async () => {
  var res = await getLatestPortfolio();
  if (res.status === 200) {
    setSpotlightPortfolio({ ...res.data });
    if (res.data.status === "Processing") {
      pollForPortfolio(res.data.id);
    }
  } else if (res.status === 204) {
    // no portfolios example is handled by getPortfolios
    return;
  } else if (res.status === 404 || res.status === 403) {
    navigate("/login");
  } else {
    setGetPortfoliosErrMsg(res.data.msg);
  }
};
const handleRefresh = async (id, src, justReload = false) => {
  var res;
  if (!justReload) {
    res = await refreshPortfolioRun(id);
  }
  if (justReload || res.status === 200) {
    res = await getPortfolioRun(id);
    if (res.status === 200) {
      // portfolio ready, update data everywhere
      setSpotlightPortfolio(res.data);
      handleUpdatePortfolioData(id, res.data);
      return;
    } else if (res.status === 202) {
      // portfolio not ready, update status everywhere
      setSpotlightPortfolio(prevState => ({
        ...prevState,
        status: "Processing",
      }));
      return pollForPortfolio(id);
    } else if (res.status === 403 || res.status === 404) {
      navigate("/login");
    } else {
      // portfolio failsauce, update data everywhere 
      // console.log(`res: ${JSON.stringify(res)}`)
      setSpotlightPortfolio(prevState => ({
        ...prevState,
        isErr: true,
        msg: res.data.msg,
      }));
      return;
    }
  } else if (res.status === 202) {
    // portfolio not ready, update status everywhere
    setSpotlightPortfolio(prevState => ({
      ...prevState,
      status: "Processing",
    }));
    return pollForPortfolio(id);
  } else if (res.status === 403 || res.status === 404) {
    navigate("/login");
  } else {
    // portfolio failsauce, update data everywhere 
    // console.log(`res: ${JSON.stringify(res)}`)
    setSpotlightPortfolio(prevState => ({
      ...prevState,
      isErr: true,
      msg: res.data.msg,
    }));
    return;
  }
};
const handleSelectTopPortfolio = (data) => {
  const id = data['id'];
  if (topPortfoliosSelected.some(e => e?.id === id)) {
    // if portfolio is already checked, remove it from selected
    setTopPortfoliosSelected(prevState => {
      return prevState.filter(item => item['id'] !== id);
    });
    return;
  }
  if (topPortfoliosSelected.length === 3) {
    return;
  }
  setTopPortfoliosSelected(prevState => ([
    ...prevState,
    data,
  ]));
};
const handleAddPortfolio = (id, data) => {
  // TODO: always call get top performers?
  _setPortfolios(prevState => ([
    data,
    ...prevState,
  ]));
  _setPortfolioDataMap(prevState => ({
    [id]: data,
    ...prevState,
  }));
};
const handleUpdatePortfolioData = (id, data) => {
  const i = portfolios.findIndex((obj => obj.id === id));
  const newPortfolios = [...portfolios];
  newPortfolios[i] = data;
  _setPortfolios(newPortfolios);
  _setPortfolioDataMap(prevState => ({
    [id]: data,
    ...prevState,
  }));
};
const handleDeletePortfolio = (id) => {
  // if this is the only portfolio
  if (portfolios.length === 1 && portfolios[0]['id'] === id) {
    if (id === -1) {
      // dont let user remove the example portfolio
      return;
    }
    _setPortfolios([_examplePortfolio]);
    _setPortfolioDataMap({ '-1': _examplePortfolioData});
    setSpotlightPortfolio(_examplePortfolioData);
  }
  // if last remaining portfolio will be example
  if (portfolios.length === 2 && portfolios.some(x => x.id === -1)) {
    _setPortfolios([_examplePortfolio]);
    _setPortfolioDataMap({ '-1': _examplePortfolioData});
    setSpotlightPortfolio(_examplePortfolioData);
  }
  // remove the portfolio
  _setPortfolios(prevState => {
    return prevState.filter(item => item['id'] !== id);
  });
  if (portfolioDataMap.hasOwnProperty(id)) {
    _setPortfolioDataMap(prevState => {
      delete prevState[id];
      return prevState;
    });
  }
  // to reset spotlight in Dash and MyPortfolios
  fetchLatestPortfolio();
  
};
const handleSelectPortfolioSpotlight = async (id) => {
  let res = await getPortfolioData(id, portfolios, portfolioDataMap, handleUpdatePortfolioData, navigate);
  setSpotlightTitle('Top Performer Spotlight');
  setSpotlightPortfolio(res);
};
const pollForPortfolio = async (id) => {
  if (id <= 0) {
    return;
  }
  let portfolioPollAttempts = 0;
  while (portfolioPollAttempts < 20) {
    let res = await getPortfolioRun(id);
    if (res.status === 202) {
      portfolioPollAttempts++;
      await wait(5000);
      continue;
    } else if (res.status === 200) {
      if (spotlightPortfolio['id'] === id) {
        setSpotlightPortfolio({ ...res.data });
        handleUpdatePortfolioData(id, res.data);
      }
      return;
    } else if (res.status === 403 || res.status === 404) {
      navigate("/login");
    } else {
      if (spotlightPortfolio['id'] === id) {
        setSpotlightPortfolio(prevState => ({
          ...prevState,
          isErr: true,
          msg: res.data.msg,
        }));
      }
      return;
    }
  }
  if (spotlightPortfolio['id'] === id) {
    setSpotlightPortfolio(prevState => ({
      ...prevState,
      isErr: true,
      msg: `We were unable to retrieve this portfolio's data, please contact help@gofolio.com and provide this value: '${id} (max gets)' along with your login name.`,
    }));
  }
};

useEffect(() => {
  const load = async () => {
    await Promise.all([
    fetchPortfolios(),
    fetchAllSecurities(),
    fetchDashboard()])
  }
  load();
}, []);

useEffect(() => {
  const fullHeight = () => {
    const elements = document.querySelectorAll('.js-fullheight');
    elements.forEach((element) => {
      element.style.height = window.innerHeight + 'px';
    });
  };

  const handleResize = () => {
    fullHeight();
  };

  window.addEventListener('resize', handleResize);

  const sidebarToggle = () => {
    const sidebar = document.getElementById('sidebar');
    sidebar.classList.toggle('active');
  };

  const sidebarCollapse = document.getElementById('sidebarCollapse');
  sidebarCollapse.addEventListener('click', sidebarToggle);

  // Clean up event listeners
  return () => {
    window.removeEventListener('resize', handleResize);
    sidebarCollapse.removeEventListener('click', sidebarToggle);
  };
}, []);

return (
  <>
    <Modal show={showExtendSessionModal}>
      <div className="text-center py-3">
        <img src={logo} width="228.996px" height="93.9961" alt='' />
      </div>

      <div className="card add-portfolio-card mx-auto w-75 w-xs-100 mb-2">
        <div className="card-body mb-2">
          <div className="container text-center px-1 pt-1">
            <h2>Timeout Warning</h2>
            <p>{isIdle ? 'You are about to be logged out due to inactivity.' : canRefresh ? 'Your session is expiring but can be extended.' : 'Your session is expiring and you will need to login again.'}</p>
            {/* TODO: handle: 1. session 2. idle AND 1. idle 2. sesssion */}
            <p>Time remaining:</p>
            <p>{isIdle ? secondsToMinutesAndSeconds(remainingIdleTime) : secondsToMinutesAndSeconds(remainingSessionTime)}</p>
            {refreshTokenErrMsg ? <div className="text-center">
              <p className="error-msg">{refreshTokenErrMsg}</p>
            </div> : null}
            <div className="row">
              {isIdle ? <>
                <div className="col">
                  <button className="btn button-dark" onClick={handleLogOut}>
                    Logout
                  </button>
                </div>
                <div className="col">
                  <button className="btn button-light" onClick={() => handleStayLoggedIn(false)}>
                    Stay Logged In
                  </button>
                </div>
              </> : canRefresh ? <>
                <div className="col">
                  <button className="btn button-dark" onClick={handleLogOut}>
                    Logout
                  </button>
                </div>
                <div className="col">
                  <button className="btn button-light" onClick={() => handleStayLoggedIn(true)}>
                    Continue Session
                  </button>
                </div>
              </> :
                <div className="col">
                  <button className="btn button-dark" onClick={handleLogOut}>
                    Re-authenticate
                  </button>
                </div>
              }
            </div>

          </div>
        </div>

      </div>
    </Modal>
    <div className="modal add-portfolio show" id="manageSessionModal" data-show={showExtendSessionModal ? 'true' : 'false'}>

    </div>
    <Navbar page={page} setPage={handleSetPage} />
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar setPage={handleSetPage} page={page} />
      <section className="content">
        <div className='content-wrapper'>
          {page === 'dashboard' ? <>
            <Nav heading="Dashboard" setPage={handleSetPage} page={page} name={name} />

            <div className="pricing-faq">
              <h2 className="ps-3 text-white">Top Performers</h2>
            </div>
            {topPortfoliosLoading ? <div className="portfolio-container">
              <div className="row justify-content-center text-center"><div className="col top-performer-loading-placeholder"><div className="mt-5 spinner-grow"></div></div></div> </div> :
              <TopPerformerList portfolios={topPortfolios} topPortfoliosSelected={topPortfoliosSelected} handleSelectTopPortfolio={handleSelectTopPortfolio} portfolioClickHook={handleSelectPortfolioSpotlight} />}

            <div className="row justify-content-center text-center">
              <div className="col pt-2">
                <button className="btn button-light" onClick={() => setPage('portfolioForm')}>{isLoadingSecurities || getSecuritiesErr ? <span className="spinner-grow spinner-grow-sm" style={{ color: '#026773' }}></span> : 'CREATE NEW CALCULATION'}</button>
              </div>
              <div className="col pt-2">
                <button className="btn button-dark" onClick={() => setPage('portfolios')}>COMPARE SELECTED</button>
              </div>
            </div>

            <div className="pricing-faq mt-2">
              <h2 className="ps-3 text-white">{spotlightTitle}</h2>
            </div>

            {getPortfoliosErrMsg ?
              <div className="row justify-content-center text-center py-5">
                <div className="col">
                  <p className="error-msg">{`There was a problem retrieving your latest portfolio. ${getPortfoliosErrMsg}`}</p>
                </div>
              </div>
              : null}

            {getSecuritiesErr ?
              <div className="row justify-content-center text-center py-5">
                <div className="col">
                  <p className="error-msg">{`There was a problem loading the securities for this page. ${getSecuritiesErr}`}</p>
                </div>
              </div>
              : null}

            <Spotlight data={spotlightPortfolio} handleRefresh={handleRefresh} src={"spotlight"}></Spotlight>

          </> : null}

          {page === 'account' ? <MyAccount setPage={handleSetPage} page={page} name={name}></MyAccount> : null}
          {page === 'securities' ? <MySecurities setPage={handleSetPage} page={page} securities={securities} name={name}></MySecurities> : null}
          {page === 'portfolios' ? <MyPortfolios portfolios={portfolios} handleAddPortfolio={handleAddPortfolio} handleUpdatePortfolioData={handleUpdatePortfolioData} handleDeletePortfolio={handleDeletePortfolio} portfolioDataMap={portfolioDataMap} topPortfoliosSelected={topPortfoliosSelected} spotlightPortfolio={spotlightPortfolio} setPage={handleSetPage} page={page} name={name}></MyPortfolios> : null}
          {page === 'portfolioForm' ? <NewPortfolioForm handleAddPortfolio={handleAddPortfolio} setPage={handleSetPage} setSpotlightPortfolio={setSpotlightPortfolio} setSpotlightTitle={setSpotlightTitle} securities={securities} name={name}></NewPortfolioForm> : null}
          {page === 'faq' ? <FAQAccordion></FAQAccordion> : null}

        </div>
      </section>
    </div >
  </>
)
}

export default Dashboard;
