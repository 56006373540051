import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import { oauthLogin } from '../../api/api';


const OAuthLogin = () => {
    const { instance, accounts } = useMsal();
    const [err, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            }).then(async (response) => {
                const res = await oauthLogin(response.accessToken);
                if (res.status !== 200) {
                    setError(`status: ${res.status} data: ${res.data}`);
                } else {
                    navigate("/app");
                }
            })
    }, [instance]);

    return (<>
        {err === '' ?
            <div class="container">
                <div className="row align-items-center justify-content-center text-center pt-5">
                    <div className="col">
                        <div className="spinner-grow" role="status">
                        </div>
                    </div>
                </div>
            </div>
            : <div class="container">
                <div className="row align-items-center justify-content-center text-center pt-5">
                    <div className="col-12">
                        <p>There was an error logging you in, please contact help@gofolio.com and provide this information:</p>
                    </div>
                    <div className="col">
                        <p>{err}</p>
                    </div>
                </div>
            </div>
        }
    </>)

};

export default OAuthLogin;